module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Cloud Hosting / Mac","start_url":"/","background_color":"#F8F8F8","theme_color":"#F8F8F8","display":"minimal-ui","icon":"src/images/mountainpass-logo.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"5eb0cc749fcee07d483dabc301050057"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M35BDGD","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
